import { createAppKit } from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { arbitrum, bsc, bscTestnet } from '@reown/appkit/networks' ;
import { WALLET_CONNECT_PROJECT_ID, app_status } from "../../../config/config";

// 1. Get projectId
const projectId = WALLET_CONNECT_PROJECT_ID;

// 2. Set the networks
// const networks = [bscTestnet];
const networks = app_status === "LIVE" ? [bsc] : [bscTestnet];

// 3. Create a metadata object - optional
const metadata = {
  name: 'MyFreedom AI',
  description: 'MyFreedom AI',
  url: 'https://mynextlevel-online.com/', // origin must match your domain & subdomain
  icons: ['https://admin.mynextlevel-online.com/storage/uploads/logos/logo-11732265351.png/']
}

// 4. Create a AppKit instance
createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    // analytics: false, // Optional - defaults to your Cloud configuration
    email: false, // default to true
    socials: [],
    // emailShowWallets: true, // default to true
  },
  allWallets: 'ONLY_MOBILE', // default to SHOW
})

export default function Web3Provider({ children }) {
  return <>{children}</>
}