import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom-toast.css";
import i18n from "./i18n";
import store from "./store/store.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Web3Provider from "../src/components/Common/cryptoPay/Web3Provider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Web3Provider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <App />
            <ToastContainer
              position="bottom-center"
              pauseOnHover={false}
              autoClose={2000}
              theme="colored"
              transition={Flip}
            />
          </I18nextProvider>
        </QueryClientProvider>
      </Provider>
    </Web3Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
