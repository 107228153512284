import { useAppKitProvider, useAppKitAccount, useAppKitNetwork, useAppKitState, useDisconnect, useAppKit } from "@reown/appkit/react";
import { BrowserProvider, Contract, formatUnits, parseUnits, isAddress } from 'ethers'
import { bsc, bscTestnet } from '@reown/appkit/networks'
import { useState ,useEffect} from "react";
import checkAndSwitchToBSC from './checkAndSwitchToBSC'
import { app_status as HOST } from "../../../config/config";


// const addBSCNetwork = async () => {
//     if (window.ethereum) {
//       try {
//         // Request to add the BSC network to MetaMask
//         await window.ethereum.request({
//           method: "wallet_addEthereumChain",
//           params: [
//             {
//               chainId: "0x38", // Hexadecimal for 56, the Chain ID for BSC Mainnet
//               chainName: "Binance Smart Chain",
//               nativeCurrency: {
//                 name: "Binance Coin",
//                 symbol: "BNB",
//                 decimals: 18,
//               },
//               rpcUrls: ["https://bsc-dataseed.binance.org/"],
//               blockExplorerUrls: ["https://bscscan.com"],
//             },
//           ],
//         });
//         console.log("BSC network added successfully");
//       } catch (error) {
//         console.error("Failed to add BSC network:", error);
//       }
//     } else {
//       console.error("window.ethereum is not available. Install MetaMask or another wallet extension.");
//     }
//   };



// open({ view: 'Account' }) //Connect
const USDTAddress = HOST === "LIVE" ? '0x55d398326f99059ff775485246999027b3197955' : '0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48'
// const USDTAddress = '0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48' // USDT - T

// const myChainId = 97

// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)
const USDTAbi = [
    'function name() view returns (string)',
    'function symbol() view returns (string)',
    'function balanceOf(address) view returns (uint)',
    'function decimals() view returns (uint)',
    'function transfer(address to, uint amount)',
    'event Transfer(address indexed from, address indexed to, uint amount)'
]

// <WalletPay
// title="Pay with Web3 Wallet"
// amount={1}
// oninitiate={(initiate) => {
//   console.log({ initiate })
// }}
// onsubmit={(result) => {
//   console.log({ result })
// }}
// toAddress="0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23"
// // disabled={false}
// // loading={false}
// // onClick={() => null}
// token={"USDT"}
// />



function WalletPay({
    toAddress = '0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23',
    title = "Pay with Web3 Wallet",
    amount = 1,
    onClick = _ => null,
    oninitiate = _ => null,
    onsubmit = _ => null,
    // disabled = false,
}
) {
    const [processing, setProcessing] = useState(false)
    const [disabled ,setDisabled]=useState(false)
    const { address, isConnected } = useAppKitAccount()
    const { walletProvider } = useAppKitProvider('eip155')
    const { caipNetwork, caipNetworkId, chainId, switchNetwork, addNetwork } = useAppKitNetwork()
    const { open, close } = useAppKit()
    const { disconnect } = useDisconnect()

    useEffect(()=>{
        console.log("isConnected",isConnected);
        if(isConnected) _onsubmit({ status: false, message: null, data: null })
    },[isConnected])
    // disconnect()

    const _onsubmit = (data) => {
        onsubmit?.(data)
        setProcessing(false)
        setDisabled(false)
    }
    

    const submit = async () => {

       
       onClick?.()
        
        if (!isConnected) {
           
            _onsubmit({ status: false, message: "disconnected", data: null })
            open?.()
            return
        } else {
            const status = await checkAndSwitchToBSC()
            if (!status) _onsubmit({ status: false, message: "Failed to switch BSC network, retry after adding manually.", data: null })
            console.log({ status });
        }


        try {
    
            // if (chainId !== myChainId) {
            //     switchNetwork(bscTestnet)
            //     return
            // }

            if (!toAddress) throw Error('toAddress is missing')
            else if (!isAddress(toAddress)) throw Error('toAddress is not valid address')

            // onClick?.()
            
            setProcessing(true)
            setDisabled(true)

            const ethersProvider = new BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()

            const balanceETH_wei = await ethersProvider.getBalance(address);
            const balanceETH = parseFloat(formatUnits(balanceETH_wei, 18))

            // ETH balance checks
            if (balanceETH == 0) {
                _onsubmit({ status: false, message: `Insufficient BNB balance - ${balanceETH}`, data: null })
                return
            }




            // The Contract object
            const USDTContract = new Contract(USDTAddress, USDTAbi, signer)
            const USDTBalance = await USDTContract.balanceOf(address)
            const USDTDecimal = await USDTContract.decimals()

            const balance = formatUnits(USDTBalance, USDTDecimal)

            // Contract balance checks
            if (parseFloat(balance) < parseFloat(amount)) {
                _onsubmit({ status: false, message: `Insufficient balance - ${balance}`, data: null })
                return
            }

            const amountInWei = parseUnits(amount.toString(), USDTDecimal);

            // Send the transfer transaction
            const tx = await USDTContract.transfer(toAddress, amountInWei);
            console.log("Transaction sent. Waiting for confirmation...");
            setProcessing(true)

            // Get the transaction hash
            const txHash = tx.hash;
            console.log("Transaction sent. Hash:", txHash);
            if (txHash) oninitiate?.(txHash)


            // Here, you can save the transaction hash to your database
            // Example: saveTransactionHashToDatabase(txHash);

            console.log("Waiting for confirmation...");

            // Wait for the transaction to be mined
            const receipt = await tx.wait(1); // Wait for 1 block confirmation
            console.log("Transaction confirmed in block:", receipt);
            if (receipt) _onsubmit({ status: true, message: 'Transaction confirmed', data: receipt })

        } catch (_error) {
            const error = JSON.parse(JSON.stringify(_error))
            _onsubmit({ status: false, message: error?.shortMessage, data: null, error })
            console.error("Transaction failed:", error);
        }
    }

    const button_content = !isConnected ? 'Connect Wallet' : processing ? 'processing...' : title

    return <button
        className="btn btn-primary "
        onClick={submit}
        disabled={disabled}>
        {button_content}
    </button>

}

export default WalletPay