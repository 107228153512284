// const chainId = "0x38"; // BSC Mainnet chain ID in hexadecimal (56 in decimal)
// const chainId = "0x61"; // BSC Testnet chain ID in hexadecimal (97 in decimal)
import { app_status as HOST } from "../../../config/config";

const mode = HOST === "LIVE" ? "LIVE" : "DEMO";

const chainId = mode == "LIVE" ? "0x38" : "0x61"; 

const bscParams =
  mode == "LIVE"
    ? {
        chainId: chainId,
        chainName: "Binance Smart Chain",
        nativeCurrency: {
          name: "Binance Coin",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com"],
      }
    : {
        chainId: chainId,
        chainName: "Binance Smart Chain Testnet",
        nativeCurrency: {
          name: "Binance Coin",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
        blockExplorerUrls: ["https://testnet.bscscan.com"],
      };




const checkAndSwitchToBSC = async () => {
  var status = false;
  if (window.ethereum) {
    try {
      // Get the current chain ID
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });

      // Check if the current network is not BSC Mainnet
      if (currentChainId !== chainId) {
        // Attempt to add or switch to BSC Mainnet
        try {
          // First, try to switch to the BSC network
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainId }],
          });
          console.log("Switched to BSC network successfully");
          status = true;
        } catch (switchError) {
          // If the network has not been added, add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [bscParams],
              });
              console.log("BSC network added successfully");
              status = true;
            } catch (addError) {
              console.error("Failed to add BSC network:", addError);
            }
          } else {
            console.error("Failed to switch to BSC network:", switchError);
          }
        }
      } else {
        console.log("Already connected to the BSC network");
        status = true;
      }
    } catch (error) {
      console.error("Error checking or switching networks:", error);
    }
  } else {
    console.error(
      "window.ethereum is not available. Please install MetaMask or another wallet extension."
    );
  }
  return status;
};

export default checkAndSwitchToBSC;
